import React from "react";
import { Link } from "gatsby";
import { getImage } from "../../contentful/data-provider";
import { renderRichText } from "gatsby-source-contentful/rich-text";

export default function BlogPostsSection(props) {
	const { categories, blogPosts, activeCategory } = props;
	return (
		<section>
			<div className='container blog--container'>
				<div className='row title'>
					<div className='col'>
						<h1>Blog</h1>
					</div>
				</div>
				<div className='row blog--header'>
					<div className='col-lg-12'>
						<ul className='blog-category-list'>
							<li>
								<Link to={`/blog/category/all`} className={`${activeCategory === "all" && "active"}`}>
									All
								</Link>
							</li>
							{categories.map((category, index) => {
								return (
									<li key={index}>
										<Link
											to={`/blog/category/${category.node.slug}`}
											className={`${activeCategory === category.node.slug && "active"}`}
										>
											{category.node.title}
										</Link>
									</li>
								);
							})}
						</ul>

						<div class='dropdown blog-category-select'>
							<button class='btn btn-secondary dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
								{activeCategory.toUpperCase()}
							</button>
							<ul class='dropdown-menu'>
								<li>
									<Link className='dropdown-item' to={`/blog/category/all`}>
										All
									</Link>
								</li>
								{categories.map((category, index) => {
									return (
										<li>
											<Link className='dropdown-item' to={`/blog/category/${category.node.slug}`} key={index}>
												{category.node.title}
											</Link>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
				<div className='row blog--container__list center'>
					{blogPosts.map((post, index) => {
						if (!post.node.category) {
							return;
						}
						return (
							<div className='col-12 col-sm-6 col-lg-4 mb-lg-80' key={index}>
								<div className='blogbox'>
									<Link to={`/blog/${post.node.category.slug}/${post.node.slug}`}>
										<div className='blogbox--header'>
											<div className='tag-container'>
												{post.node.tags && post.node.tags.split(',').map((tag, index) => {
													return (
														<span className='tag' key={index}>
															{tag.title}
														</span>
													);
												})}
											</div>
											<div className='img-container'>
												<img
													src={getImage(post.node.image).url}
													className='attachment-post-thumbnail size-post-thumbnail wp-post-image'
													alt={getImage(post.node.image).title}
												/>
											</div>
										</div>
										<div className='blogbox--content'>
											<span className='date'>{post.node.publishedAt}</span>
											<h3 className='post-title'>{post.node.title}</h3>

											<div className='blogbox--content__inner'>{post.node.excerpt && renderRichText(post.node.excerpt)}</div>
											<div className='btn-container d-none'>
												<div className={`more`}>
													<i className='fas fa-arrow-right' /> Read Article
												</div>
											</div>
										</div>
									</Link>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
}
