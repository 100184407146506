import * as React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
import {DataProvider} from "../contentful/data-provider";
import BlogPostsSection from "../components/sections/_blog-posts-section";
import ogimage from "../images/ogimages/blog.png";

const CategorySingle = ({data}) => {
    const dataProvider = new DataProvider(data);
    const blogPosts = data.allContentfulBlogPost.edges;
    const categories = data.allContentfulBlogPostCategory.edges;
    const currentCategory = data.contentfulBlogPostCategory;
    return (
        <Layout dataProvider={dataProvider}>
            <Helmet
                meta={[
                    {
                        property: `og:title`,
                        content: 'Agoric - ' + currentCategory.title || 'Agoric',
                    },
                    {
                        property: `twitter:title`,
                        content: 'Agoric - ' + currentCategory.title || 'Agoric',
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `twitter:image`,
                        content: ogimage,
                    },
                    {
                        property: `og:image`,
                        content: ogimage,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                ]}
            >
                <title>Agoric - {currentCategory.title}</title>
            </Helmet>
            <main className="blog">
                <BlogPostsSection categories={categories} blogPosts={blogPosts} activeCategory={currentCategory.slug}/>
            </main>
        </Layout>
    )
}

export default CategorySingle;

export const query = graphql`
    query BlogPostByCategorySlug(
        $slug: String!
    ) {
        allContentfulBlogPost(filter: {category: {slug: {eq: $slug}}}, sort: {fields: publishedAt, order: DESC}) {
            edges {
                node {
                    id
                    title
                    slug
                    image {
                        title
                        url
                    }
                    author
                    publishedAt(formatString: "MMMM D, Y")
                    tags
                    category {
                        title
                        slug
                    }
                    body { raw }
                    excerpt { raw }
                }
            }
        }
        allContentfulBlogPostCategory(filter: {slug: {ne: "all"}, isEconomyCategory: {ne: true}}) {
            edges {
                node {
                    id
                    title
                    slug
                }
            }
        }
        contentfulBlogPostCategory(slug: {eq: $slug}) {
            id
            title
            slug
        }
    }
`
